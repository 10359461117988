<template>
    <div class="timeline-activity">
        <div class="row no-gutters">
            <div class="col-auto">
                <activity-icon :icon="activity.icon" />
            </div>
            <div class="col">
                <div class="card message-card">
                    <div class="row no-gutters">
                        <div class="col">
                            <div class="activity-details">
                                <div class="row mb-1">
                                    <div class="col-auto d-flex align-items-center">
                                        <h5 class="activity-title">
                                            {{ activity.title }}
                                        </h5>
                                    </div>
                                    <div class="col d-flex align-items-center">
                                        <!-- <button class="btn btn-primary" @click.prevent="isEditing=!isEditing">
                                            <i class="fas fa-pen" />
                                            Edit
                                        </button> -->
                                    </div>
                                </div>

                                <!-- Body -->
                                <div class="row no-gutters">
                                    <div class="col-auto">
                                        <div class="activity-content" v-html="content" />
                                    </div>
                                </div>
                                <!-- End of  body -->

                                <!--  footer -->
                                <div class="row no-gutters">
                                    <div class="col-auto">
                                        <span class="activity-due-time">Due in {{ activity.dueTime }}</span>
                                    </div>
                                    <div class="col-auto">
                                        <span class="activity-contact">
                                            <i class="fas fa-user" />
                                            {{ activity.contact }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="select-activity">
                                <div class="checkbox-circle" />
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="true" class="comment-box card">
                    <feed-form
                        class="mt-3"
                        label="Add a new comment"
                        mode="comment"
                        :parent-data="activity"
                        @load-comments="getComments"
                        @cancel="isEditing=!isEditing"
                        @saved="getComments"
                    />
                    <div v-if="messageComments.length" class="px-4">
                        <h6 class="pl-1">
                            Comments ({{ messageComments.length }})
                        </h6>
                        <div
                            v-for="comment in messageComments"
                            :key="comment.id"
                            class="comment-item"
                        >
                            <div class="comment-item__header">
                                <div class="title">
                                    <profile-image :url="getUserPhoto(user)" />
                                    <span class="ml-2">Username</span>
                                </div>
                                <span> {{ comment.created_at }}</span>
                            </div>
                            <div
                                class="comment-item__body"
                                v-html="comment.message"
                            />
                            <div class="comment-item__footer">
                                <button class="btn action-button">
                                    <i class="fa fa-thumbs-up" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FeedForm from "@c/feed/feed-form.vue";
import ProfileImage from "../profile-image";
import { mapState } from "vuex";

export default {
    name: "TimelineActivity",
    components: {
        FeedForm,
        ProfileImage,
        ActivityIcon: () => import(/* webpackChunkName: "activity-icon" */ "./activity-icon")
    },
    props: {
        activity: {
            type: Object,
            required: true
        },
        allowComments: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isEditing: false
        }
    },
    computed: {
        ...mapState({
            user: state => state.User.data
        }),
        content() {
            const message = this.activity.message ? JSON.parse(this.activity.message) : {}
            return message.text;
        },
        messageComments() {
            const hasComments = this.activity.comments && this.activity.comments.length;
            return hasComments ? this.activity.comments.filter(comment => comment.message_id == this.activity.id) : [];
        }
    },
    methods: {
        getComments() {
            axios(`/messages/${this.activity.id}/comments`).then(({ data }) => {
                this.$set(this.activity, "comments", data);
            })
        },
        getUserPhoto(user) {
            return user.photo ? user.photo.url : "";
        }
    }
}
</script>

<style lang="scss">
.timeline-activity {
    .message-card {
        position: relative;
        border: 1px solid rgba(202, 206, 213, .46);
        padding: 0;
        margin-left: 10px;
        margin-bottom: 0;

        &::before {
            content: "";
            position: absolute;
            width: 15px;
            height: 15px;
            border-bottom: 1px solid rgba(202, 206, 213, .46);
            border-left: 1px solid rgba(202, 206, 213, .46);
            background-color: white;
            left: -8px;
            top: 37px;
            transform: scaleY(1.3) rotate(45deg);
        }

        .activity-details {
            padding: 20px;

            .activity-title {
                font-family: "Roboto", sans-serif;
                font-size: 16px;
                margin-bottom: 0;
            }

            .activity-due-time {
                font-family: "Roboto", sans-serif;
                color: #A5A5A5;
                font-size: 12px;
                font-weight: 100;
                margin-right: 15px;
            }

            .activity-contact {
                font-family: "Roboto", sans-serif;
                color: #A5A5A5;
                font-size: 12px;
                font-weight: 700;

                i {
                    margin-right: 5px;
                }
            }
        }

        .select-activity {
            background-color: #E1E8ED;
            height: 100%;
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            .checkbox-circle {
                width: 25px;
                height: 25px;
                background-color: white;
                border-radius: 100%;
                border: 1px solid #B3C1CB;
            }
        }
    }
}

.comment-box {
    margin-top: 5px;
    margin-left: 10px;
    padding: 0 0 0 0 !important;
    border-top: 2px solid #ddd;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        background-color: white;
        left: 10px;
        top: -5px;
    }
}

.comment-item {
    border-top: 1px solid #eee;
    padding: 15px 10px;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;;
        margin-bottom: 5px;
        color: var(--base-color);

        .title {
            display: flex;
            align-items: center;
        }
    }

    &__body {
        padding-top: 10px;
        padding-left: 60px;
    }

    &__footer {
        text-align: right;
        color: var(--base-color);
    }
}
</style>
